import React from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"
import { Grid } from '@material-ui/core';


const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: hidden;
  position: relative;
`

const Content = styled.div`
 position: absolute;
 width: 350px;
 left: 38%;
 transform: translateX(-50%);
 padding-top: 15%;
 z-index: 95;

 @media ${props => props.theme.breaks.down('md')} {
  position: relative;
  padding-top: 10%;
  left: 10%;
  transform: translateX(0)
 }
`

const BackDrop = styled.div`
  height: 110vh;
  position: absolute;
  width: 50%;
  right: 10%;
  top: -80px;
  /* background-color: pink; */
  overflow: hidden;
  z-index: 90;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const DuckMobile = styled.div`
  margin-top: 50px;
  height: 250px;
  display: flex;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }


  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }
`

const Image = styled.div`
  background-image:  url(${props => props.src});
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${props => props.theme.breaks.down('md')} {
    min-width: 250px;
    margin-right: 30px;
  }
`

const Overlay = styled.div`
  height: 100%;
  background-color: #000;
  opacity: .7;
  transition: opacity .3s;

  &:hover {
    opacity: 0;
  }
`

const Title = styled.h2`
  width: 350px;
  color: ${props => props.theme.colors.body};
  font-size: 8rem;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 6rem;
    margin-top: 50px;
  }
`

const Subtitle = styled.h4`
 color: ${props => props.theme.colors.body};
 font-weight: 300;
 width: 350px;
 line-height: 1.1;
 margin-top: 15px;

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 2rem;
    margin-top: 40px;
    line-height: 1.2;
  }
`


const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`


const DuckPage = ({ data }) => {


  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body} pageTitle="Look at a duck">
      <Container>

        <Content>
          <Title>Look at a duck</Title>
          <Subtitle>Your day is long and you could probably use some rest. So why not look at a duck and relax a bit?</Subtitle>
        </Content>

        <BackDrop>
          <Grid container spacing={6} justify="space-between" style={{height: '36%'}}>
            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_1_odhd3x.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639107/Duck_2_t7ovgv.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_3_qrngsv.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
          </Grid>
          
          <Grid container spacing={6} justify="space-between" style={{height: '40%'}}>

            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_4_kncjgq.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639109/Duck_5_edc6ps.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_6_sf3pvt.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>

          </Grid>

          <Grid container spacing={6} justify="space-between" style={{height: '40%'}}>

            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_7_kd8jg8.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639110/Duck_8_hxxplj.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
            <Grid item md={4}><Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639110/Duck_9_ssahfa.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image></Grid>
            
          </Grid>
        </BackDrop>

        <DuckMobile>
          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_1_odhd3x.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>
          
          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639107/Duck_2_t7ovgv.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>

          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_3_qrngsv.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>

          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_4_kncjgq.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>

          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639109/Duck_5_edc6ps.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>

          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_6_sf3pvt.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>

          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639108/Duck_7_kd8jg8.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>

          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639110/Duck_8_hxxplj.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>

          <Image src="https://res.cloudinary.com/celebrand/image/upload/v1631639110/Duck_9_ssahfa.jpg" className="duck-img"><Overlay></Overlay>&nbsp;</Image>
        </DuckMobile>
        
        
         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default DuckPage;
